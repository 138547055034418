import React, { useState } from 'react';
import { useFactsQuery, useFactQuery } from './generated/hooks';
import { PrimaryButton, List } from '@fluentui/react';
import { FactType } from './generated/schemas';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

function Many() {
  const onRenderCell = React.useCallback((item: FactType | undefined, index: number | undefined) => {
    return (
      <p>CATS: {item?.category}</p>
    );
    }, []);
  const { data, loading, error } = useFactsQuery({
    variables: {
      user: "Mikey2"
    },
  });
  const [facts, setFacts] = useState<Array<FactType>>([]);
  if (loading) {
    return (
      <div>
        <Spinner size={SpinnerSize.large} />
      </div>
    )
  } else {
    if (facts?.length === 0) {
      setFacts(data?.facts as Array<FactType>);
    }
  }
  if (error) {
    return (
      <div>
        <p>ERROR</p>
      </div>
    )
  }
  return (
    <div>
      <p>Loading?: {loading as boolean}</p>
      {data?.facts?.map(fact => <p key={fact?.guid}>name={fact?.category}</p>)}
      <p>-------len: {facts?.length}--------</p>
      <List items={facts} 
        onRenderCell={onRenderCell}/>
      <p>---------------</p>
    </div>
  )
}

function Main() {
  const { data, loading, error } = useFactQuery({
    variables: {
      user: "Mikey"
    },
  });
  return (
    <div>
      <PrimaryButton>Button</PrimaryButton>
      <p>Hello, I Must Be Going Again {data?.fact?.category}</p>
      <p>Loading: '{loading}'</p>
      <p>Error: '{error}'</p>
      <p>Guid: {data?.fact?.guid}</p>
      <p>Category: {data?.fact?.category}</p>
      <Many />
    </div>
  );
}

export default Main;