import React from 'react';
import logo from './logo.svg';
import './App.css';
import { client } from './ApolloClient';
import { ApolloProvider } from '@apollo/client';
import gql from 'graphql-tag';
import Main from './Main';

function App() {
  return (
    <ApolloProvider client={client}>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <Main />
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
    </ApolloProvider>
  );
}

export default App;

client.query({
  query: gql`
    query {
      facts(user: "Mike") {
        category,
        guid
      }
    }
  `
}).then(result => console.log(result));