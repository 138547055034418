import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}

export const FactDocument = gql`
    query Fact($user: String) {
  fact(user: $user) {
    guid
    category
  }
}
    `;

/**
 * __useFactQuery__
 *
 * To run a query within a React component, call `useFactQuery` and pass it any options that fit your needs.
 * When your component renders, `useFactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFactQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useFactQuery(baseOptions?: Apollo.QueryHookOptions<Types.FactQuery, Types.FactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FactQuery, Types.FactQueryVariables>(FactDocument, options);
      }
export function useFactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FactQuery, Types.FactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FactQuery, Types.FactQueryVariables>(FactDocument, options);
        }
export type FactQueryHookResult = ReturnType<typeof useFactQuery>;
export type FactLazyQueryHookResult = ReturnType<typeof useFactLazyQuery>;
export type FactQueryResult = Apollo.QueryResult<Types.FactQuery, Types.FactQueryVariables>;
export const FactsDocument = gql`
    query Facts($user: String) {
  facts(user: $user) {
    guid
    category
    dayCreated
    json
    templateName
  }
}
    `;

/**
 * __useFactsQuery__
 *
 * To run a query within a React component, call `useFactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFactsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useFactsQuery(baseOptions?: Apollo.QueryHookOptions<Types.FactsQuery, Types.FactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FactsQuery, Types.FactsQueryVariables>(FactsDocument, options);
      }
export function useFactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FactsQuery, Types.FactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FactsQuery, Types.FactsQueryVariables>(FactsDocument, options);
        }
export type FactsQueryHookResult = ReturnType<typeof useFactsQuery>;
export type FactsLazyQueryHookResult = ReturnType<typeof useFactsLazyQuery>;
export type FactsQueryResult = Apollo.QueryResult<Types.FactsQuery, Types.FactsQueryVariables>;